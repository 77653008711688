import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.less";
import "@/assets/scss/global.scss";
import Directives from "@/directive";

createApp(App).use(store).use(router).use(Directives).use(Antd).mount("#app");

window.addEventListener("error", function onError(e) {
  console.log(e.message);
  if (e.message.includes("ResizeObserver")) {
    document.querySelector("#webpack-dev-server-client-overlay")?.remove();
  }
});
