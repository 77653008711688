import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Frame",
    component: () => import("@/views/Frame.vue"),
    redirect: "/pest",
    children: [
      // 虫害列表
      {
        path: "/pest",
        name: "Pest",
        component: () => import("@/views/Pest/Pest.vue"),
      },
      // 虫害详情
      {
        path: "/pest/:pointId",
        name: "PestDetail",
        component: () => import("@/views/Pest/PestDetail/PestDetail.vue"),
      },
      // 统计分析
      {
        path: "/statistics/analyze",
        name: "Analyze",
        component: () => import("@/views/Statistics/Analyze/Analyze.vue"),
      },
      // 数据预测
      {
        path: "/statistics/predict",
        name: "Predict",
        component: () => import("@/views/Statistics/Predict/Predict.vue"),
      },
      // 病害动态建模
      {
        path: "/diseaseModel/diseaseModeling",
        name: "DiseaseModeling",
        component: () =>
          import("@/views/DiseaseModel/DiseaseModeling/DiseaseModeling.vue"),
      },
      // 病害动态建模-模型详情
      {
        path: "/diseaseModel/diseaseModeling/:modelId",
        name: "diseaseModelingDetail",
        component: () =>
          import("@/views/DiseaseModel/DiseaseModeling/Detail/Detail.vue"),
      },
      // 执行记录
      {
        path: "/diseaseModel/executionRecord",
        name: "ExecutionRecord",
        component: () =>
          import("@/views/DiseaseModel/ExecutionRecord/ExecutionRecord.vue"),
      },
      // 植保情报
      {
        path: "/information",
        name: "Information",
        component: () => import("@/views/Information/Information.vue"),
      },
      // 植保情报-新建/编辑
      {
        path: "/information/detail",
        name: "InformationDetail",
        component: () =>
          import(
            "@/views/Information/components/Template/Detail/TemplateDetail.vue"
          ),
      },
      // 植保情报-模版导出
      {
        path: "/information/export",
        name: "InformationExport",
        component: () =>
          import(
            "@/views/Information/components/Template/Export/ExportTemplate.vue"
          ),
      },
      // 植保情报-模版导出
      {
        path: "/information/preview/:recordId",
        name: "InformationPreview",
        component: () =>
          import(
            "@/views/Information/components/ExportList/TemplatePreview.vue"
          ),
      },
      // 互动交流-问卷调查
      {
        path: "/interaction/survey",
        name: "InteractionSurvey",
        component: () => import("@/views/Interaction/Survey/Survey.vue"),
      },
      // 互动交流-问卷调查-手机填写端
      {
        path: "/interaction/survey/:qrCode",
        name: "InteractionSurveyMobile",
        component: () =>
          import("@/views/Interaction/Survey/Preview/SurveyMobile.vue"),
      },
      // 互动交流-问卷调查-新建
      {
        path: "/interaction/survey/new",
        name: "InteractionSurveyNew",
        component: () => import("@/views/Interaction/Survey/New/New.vue"),
      },
      // 互动交流-问卷调查-编辑
      {
        path: "/interaction/survey/edit/:surveyId",
        name: "InteractionSurveyEdit",
        component: () => import("@/views/Interaction/Survey/Edit/Edit.vue"),
      },
      // 互动交流-问卷调查-统计
      {
        path: "/interaction/survey/statistic/:surveyId",
        name: "InteractionSurveyStatistic",
        component: () =>
          import("@/views/Interaction/Survey/Statistic/Statistic.vue"),
      },
      // 互动交流-咨询回答
      {
        path: "/interaction/feedback",
        name: "InteractionFeedback",
        component: () => import("@/views/Interaction/Feedback/Feedback.vue"),
      },
      // 互动交流-咨询回答-详情
      {
        path: "/interaction/feedback/:feedbackId",
        name: "InteractionFeedbackDetail",
        component: () =>
          import("@/views/Interaction/Feedback/Detail/Detail.vue"),
      },
      // 农业知识库-农业百科
      {
        path: "/encyclopedia",
        name: "Encyclopedia",
        component: () => import("@/views/Encyclopedia/Encyclopedia.vue"),
      },
      // 农业知识库-农业百科-详情
      {
        path: "/encyclopedia/:id",
        name: "EncyclopediaDetail",
        component: () => import("@/views/Encyclopedia/Detail/Detail.vue"),
      },
      // 农业知识库-知识图谱
      {
        path: "/domain",
        name: "Domain",
        component: () => import("@/views/Domain/Domain.vue"),
      },
      // 农业知识库-知识图谱-详情
      {
        path: "/domain/:id",
        name: "DomainDetail",
        component: () => import("@/views/Domain/Detail/Detail.vue"),
      },
      // 虫害预警
      {
        path: "/precaution",
        name: "Precaution",
        component: () => import("@/views/Independent/Precaution.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const token = new URLSearchParams(window.location.search).get("token");

  if (token && !to.query.token) {
    next({
      path: to.path,
      query: {
        ...to.query,
        token,
      },
    });
  } else {
    next();
  }
});

export default router;
