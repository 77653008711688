import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { message } from "ant-design-vue";

class Http {
  instance: AxiosInstance; // axios的实例将被保存到这里

  constructor(config: AxiosRequestConfig) {
    //创建axios实例
    this.instance = axios.create(config);
    //请求拦截器
    this.instance.interceptors.request.use(
      (config) => {
        const token = new URLSearchParams(window.location.search).get("token");

        if (config.headers && token) {
          config.headers.token = token;
        }

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    //响应拦截器
    this.instance.interceptors.response.use(
      (response) => {
        if (response.data?.code && response.data?.code !== 200) {
          message.error(response.data?.msg);
        }
        return response.data;
      },
      (error) => {
        message.error(error.response?.data.msg ?? error.message);
        return Promise.reject(error);
      }
    );
  }

  request<T>(config: AxiosRequestConfig): Promise<T> {
    return new Promise((resolve, reject) => {
      this.instance
        .request<any, T>(config)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  get<T = any>(
    url: string,
    params: any = {},
    config: AxiosRequestConfig = {}
  ): Promise<T> {
    return this.request<T>({ url, params, ...config, method: "GET" });
  }
  post<T = any>(
    url: string,
    data: any = {},
    config: AxiosRequestConfig = {}
  ): Promise<T> {
    return this.request<T>({ url, data, ...config, method: "POST" });
  }
  delete<T = any>(
    url: string,
    params: any = {},
    config: AxiosRequestConfig = {}
  ): Promise<T> {
    return this.request<T>({
      url,
      [config.headers && config.headers["Content-Type"] === "application/json"
        ? "data"
        : "params"]: params,
      ...config,
      method: "DELETE",
    });
  }
  put<T = any>(
    url: string,
    data: any = {},
    config: AxiosRequestConfig = {}
  ): Promise<T> {
    return this.request<T>({ url, data, ...config, method: "PUT" });
  }
}
export default Http;
