import http from "@/server";
import { locale } from "dayjs";

function getPermissions(node) {
  const permissions: string[] = node.functions.map((item) => item.regexUri);
  if (node.children?.length) {
    for (const item of node.children) {
      permissions.push(...getPermissions(item));
    }
  }
  return permissions;
}

export default {
  namespaced: true,
  state: {
    info: null,
    permissions: [],
  },
  mutations: {
    setInfo(state, info) {
      state.info = info;
    },
    setPermissions(state, list) {
      state.permissions = list;
    },
  },
  actions: {
    async getInfo({ commit }) {
      try {
        const token = new URLSearchParams(window.location.search).get("token");
        if (!location.pathname.includes("/precaution")) {
          if (token) {
            const res = await http.get("/user/info");
            if (res.code === 0) {
              const permissions: string[] = [];
              for (const item of res.data.resourceTree) {
                permissions.push(...getPermissions(item));
              }

              commit("setPermissions", permissions);
              commit("setInfo", res.data);
              localStorage.setItem("permissions", JSON.stringify(permissions));
            } else {
              commit("setPermissions", []);
              commit("setInfo", null);
            }
          } else {
            // location.replace(process.env.VUE_APP_REDIRECT_URL as string);
          }
        }
      } catch (err) {
        commit("setPermissions", []);
        commit("setInfo", null);
      }
    },
  },
};
