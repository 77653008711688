export default {
  install(app) {
    app.directive("permission", {
      mounted(el: HTMLElement, { value }) {
        const PERMISSION = localStorage.getItem("permissions");
        if (PERMISSION) {
          const permissions = JSON.parse(PERMISSION);

          if (!permissions.includes(value)) {
            el.parentNode?.removeChild(el);
          }
        }
      },
    });
  },
};
